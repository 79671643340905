import { UserType } from '@shared/models/user.model';
import { isListHumidor } from './cigarlogs';

export const AppRoutes = {
  root: () => '/',
  login: () => '/login',
  register: () => '/register',
  notifications: () => '/notifications',
  social: () => '/tabs/social',
  socialUser: (userType: UserType, userId) =>
    `/tabs/social/${userType}/${userId}`,
  socialPost: (postId) => `/tabs/social/post/${postId}`,
  cigar: (id = ':CigarCreatedId') => `/cigar/${id}`,
  myCigars: () => '/tabs/my-cigars',
  myCigarsListCigar: (
    list = ':List',
    cigarLogId: number | ':CigarLogId' = ':CigarLogId'
  ) => `/tabs/my-cigars/${list}/${cigarLogId}`,
  myHumidors: () => '/tabs/my-humidors',
  myHumidorsSensor: () => `${AppRoutes.myHumidors()}/hygrometer-sensor`,
  myHumidor: (humidorId = ':List') => `${AppRoutes.myHumidors()}/${humidorId}`,
  myHumidorEdit: (humidorId = ':List') =>
    `${AppRoutes.myHumidors()}/${humidorId}/edit`,
  myHumidorMeasurmentHistory: (humidorId = ':List') =>
    `${AppRoutes.myHumidors()}/${humidorId}/measurement-history`,
  myHumidorCigarlog: (
    humidorId = ':List',
    cigarLogId: number | ':CigarLogId' = ':CigarLogId'
  ) => `${AppRoutes.myHumidors()}/${humidorId}/${cigarLogId}`,
  myCigarLog: (listId: string, cigarLogId: number) =>
    isListHumidor(listId)
      ? AppRoutes.myHumidorCigarlog(listId, cigarLogId)
      : AppRoutes.myCigarsListCigar(listId, cigarLogId),
  tools: () => '/tabs/tools',
};
